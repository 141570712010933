@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");

@font-face {
  font-family: "Icons";
  src: url("./fonts/Sacara/sacara.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FbTypoPas";
  src: url("./fonts/FbTypoPas/FbTypoPas-Regular.woff2");
  src:
    url("./fonts/FbTypoPas/FbTypoPas-Regular.woff2") format("woff2"),
    url("./fonts/FbTypoPas/FbTypoPas-Bold.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}

#root {
  height: 100%;
  min-height: 100% !important;
}

html,
body {
  height: 100%;
  min-height: 100% !important;
  margin: 0;
  font-family: "FbTypoPas", "Comfortaa" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.disable-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
  display: none;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.scrollbar::webkit-scrollbar-button {
  display: none;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* .scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
} */
